"use client";

import { BASE_URL } from "@/lib/constants";
import { cn } from "@/lib/utils";
import { LoaderPinwheel } from "lucide-react";
import NextImage from "next/image";
import { FC, useState } from "react";

type ImageProps = {
  src: string;
  alt: string;
  width: number;
  height: number;
  className?: string;
  useLoader?: boolean;
  unoptimized?: boolean;
  priority?: boolean;
  fallbackSrc?: string;
};

const Image: FC<ImageProps> = ({
  src,
  alt,
  className,
  useLoader = false,
  width,
  height,
  unoptimized,
  priority = false,
  fallbackSrc = `${BASE_URL}/images/placeholder.png`,
}) => {
  const [loading, setLoading] = useState(useLoader);
  const [error, setError] = useState(false);
  const extension = src.split(".").pop()?.toLowerCase();
  const isUnoptimized = unoptimized ?? extension === "gif";
  const handleLoad = () => {
    setLoading(false);
  };
  const handleError = () => {
    setLoading(false);
    setError(true);
  };
  const imageSrc = error ? fallbackSrc : src;
  return (
    <div className="relative flex h-full w-full items-center justify-center">
      {loading && <LoaderPinwheel className="mx-auto animate-spin" />}
      <NextImage
        unoptimized={isUnoptimized}
        src={imageSrc}
        alt={alt}
        priority={priority}
        width={width}
        height={height}
        className={cn("m-auto", className)}
        onLoad={handleLoad}
        onError={handleError}
        loading="lazy"
      />
    </div>
  );
};

export default Image;
