import Image from "@/components/image";
import { Separator } from "@/components/ui/separator";
import * as motion from "motion/react-client";

const Hero = () => {
  return (
    <div className="flex flex-col text-white justify-start w-full">
      <div className="flex flex-col sm:flex-row">
        <motion.div
          initial={{
            translateY: -10,
            opacity: 0,
          }}
          animate={{
            translateY: 0,
            opacity: 1,
            transition: { duration: 1 },
          }}
          className="rounded-3xl overflow-hidden size-32 border-2 border-zinc-800 pt-3 bg-zinc-900"
        >
          <Image
            src="/images/profile.png"
            alt="Profile"
            width={480}
            height={540}
            className="w-[90%]"
          />
        </motion.div>
        <div className="flex flex-col sm:self-end mt-4 sm:ml-4">
          <div className="flex gap-1">
            <h1 className="font-bold text-3xl font-mono tracking-tighter">
              Alain Di Fabio
            </h1>
            <div className="text-3xl">🇮🇹</div>
          </div>
          <h2 className="text-xs font-mono">
            Freelance Full Stack Developer 💻
          </h2>
        </div>
      </div>
      <Separator className="bg-zinc-800 my-4" />
    </div>
  );
};

export default Hero;
