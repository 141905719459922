import Project from "./project";

const Projects = () => {
  return (
    <div className="flex flex-col gap-4 pt-2 mb-4">
      {[
        {
          name: "vammon",
          title: "Vammon",
          href: "https://www.vammon.com",
          types: ["web", "mobile"],
        },
        {
          name: "specialist",
          title: "Specialist App",
          href: "https://www.producthunt.com/products/specialist-2",
          types: ["mobile"],
        },
        {
          name: "powermeter",
          title: "PowerMeter",
          href: "https://www.powermeter.info/index.php/features-app",
          types: ["mobile"],
        },
        {
          name: "winintegracloud",
          title: "WinIntegra Cloud",
          href: "https://www.winintegracloud.it/app-ios-android/",
          types: ["mobile"],
        },
        {
          name: "manybox",
          title: "ManyBox",
          href: "https://www.producthunt.com/products/manybox",
          types: ["mobile"],
        },
        {
          name: "looksbetter",
          title: "LooksBetter",
          href: "https://www.producthunt.com/products/looksbetter",
          types: ["web"],
        },
      ].map((project) => (
        <Project
          key={project.name}
          name={project.name}
          title={project.title}
          href={project.href}
          types={project.types as any}
        />
      ))}
    </div>
  );
};

export default Projects;
