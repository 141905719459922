import { cn } from "@/lib/utils";
import { Inter } from "next/font/google";
import { FC, ReactNode } from "react";

const inter = Inter({
  subsets: ["latin"],
});

type SpecialCardProps = {
  title: string;
  className?: string;
  children: ReactNode;
};

const SpecialCard: FC<SpecialCardProps> = ({ title, className, children }) => {
  return (
    <div className={cn(className, "flex flex-col text-zinc-300 text-justify")}>
      <div className="flex flex-col mb-1">
        <h3 className="text-xl font-medium text-zinc-500">{title}</h3>
      </div>
      <div className={cn(inter.className, "text-base gap-4 flex flex-col")}>
        {children}
      </div>
    </div>
  );
};

export default SpecialCard;
