"use client";

import Modal from "@/components/modal";
import SpecialCard from "@/components/special-card";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Link } from "@/i18n/routing";
import { MailIcon, PhoneCallIcon, StarIcon } from "lucide-react";
import * as motion from "motion/react-client";
import { useTranslations } from "next-intl";
import { SocialIcon } from "react-social-icons/component";
import "react-social-icons/linkedin";
import "react-social-icons/x";
import ContactForm from "./_components/contact-form";
import Header from "./_components/header";
import Hero from "./_components/hero";
import Projects from "./_components/projects";

export default function HomePage() {
  const t = useTranslations("Home");
  return (
    <main className="flex flex-col w-full min-h-screen">
      <Header />
      <div className="mt-4 p-2 mx-auto max-w-2xl w-full">
        <Hero />
        <motion.div
          initial={{
            translateY: 10,
            opacity: 0,
          }}
          animate={{
            translateY: 0,
            opacity: 1,
            transition: { duration: 1 },
          }}
          className="flex flex-col gap-y-4"
        >
          <SpecialCard title={t("sections.about.title")}>
            {t.rich("sections.about.content", {
              strong: (children) => <strong>{children}</strong>,
              p: (children) => <p>{children}</p>,
            })}
          </SpecialCard>
          <SpecialCard title={t("sections.dev.title")}>
            {t.rich("sections.dev.content", {
              strong: (children) => <strong>{children}</strong>,
              p: (children) => <p>{children}</p>,
            })}
          </SpecialCard>
          <SpecialCard title="" className="my-8 mx-auto">
            <motion.div
              initial={{
                translateY: 10,
                opacity: 0,
              }}
              animate={{
                translateY: 0,
                opacity: 1,
                transition: { duration: 1 },
              }}
              className="flex flex-wrap gap-4 text-black"
            >
              <div className="flex flex-wrap justify-center gap-4 mx-auto">
                <Link
                  href="https://calendly.com/alaindifabio/discovery-call"
                  target="_blank"
                  className="w-full sm:w-auto"
                >
                  <Button
                    variant="outline"
                    size="lg"
                    type="button"
                    className="w-full"
                  >
                    <PhoneCallIcon className="size-4" />
                    {t("buttons.bookACall")}
                  </Button>
                </Link>
                <Modal
                  title={t("sections.contactForm.title")}
                  description={t("sections.contactForm.description")}
                  trigger={
                    <Button
                      type="button"
                      variant="outline"
                      size="lg"
                      className="w-full sm:w-auto"
                    >
                      <MailIcon className="size-4" />
                      {t("buttons.getInTouch")}
                    </Button>
                  }
                >
                  <ContactForm />
                </Modal>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button
                      type="button"
                      size="lg"
                      className="bg-yellow-300 text-black hover:bg-yellow-400 flex-1"
                    >
                      <StarIcon className="size-4" />
                      {t("buttons.readFeedback")}
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        {t("alerts.feedbacks.title")}
                      </AlertDialogTitle>
                      <AlertDialogDescription>
                        {t("alerts.feedbacks.description")}
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>
                        {t("alerts.feedbacks.cancel")}
                      </AlertDialogCancel>
                      <AlertDialogAction
                        onClick={() =>
                          window.open(
                            "https://www.linkedin.com/in/alaindifabio/details/recommendations/",
                            "_blank"
                          )
                        }
                      >
                        {t("alerts.feedbacks.confirm")}
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
              <div className="flex items-center justify-center gap-4 w-full">
                <Link
                  href="https://www.linkedin.com/in/alaindifabio/"
                  target="_blank"
                  className="size-12"
                >
                  <SocialIcon
                    network="linkedin"
                    as="div"
                    style={{ height: "100%", width: "100%" }}
                  />
                </Link>
                <Link
                  href="https://www.x.com/adfdev/"
                  target="_blank"
                  className="size-12"
                >
                  <SocialIcon
                    network="x"
                    as="div"
                    style={{ height: "100%", width: "100%" }}
                  />
                </Link>
              </div>
            </motion.div>
          </SpecialCard>
        </motion.div>
        <motion.div
          initial={{
            translateY: 10,
            opacity: 0,
          }}
          animate={{
            translateY: 0,
            opacity: 1,
            transition: { duration: 1 },
          }}
        >
          <SpecialCard title={t("sections.projects.title")}>
            <Projects />
          </SpecialCard>
        </motion.div>
      </div>
    </main>
  );
}
