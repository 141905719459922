import { cn } from "@/lib/utils";
import {
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet";

type ModalProps = {
  className?: string;
  dialogClassName?: string;
  sheetClassName?: string;
  title?: ReactNode;
  description?: ReactNode;
  trigger?:
    | ReactNode
    | ((trigger: typeof SheetTrigger | typeof DialogTrigger) => ReactNode);
  footer?: ReactNode;
  useSheet?: boolean;
  sheetSide?: "top" | "bottom" | "left" | "right";
  onClose?: () => void;
  manualClose?: (close: () => void) => void;
  children?: ReactNode;
};

export type ModalHandle = {
  openModal: () => void;
  closeModal: () => void;
};

const Modal = forwardRef<ModalHandle, ModalProps>(
  (
    {
      className,
      dialogClassName,
      sheetClassName,
      title,
      description,
      footer,
      trigger,
      useSheet,
      sheetSide = "bottom",
      onClose,
      manualClose,
      children,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSheet, setIsSheet] = useState(useSheet || false);
    useImperativeHandle(ref, () => ({
      openModal: () => setIsOpen(true),
      closeModal: () => setIsOpen(false),
    }));
    const handleResize = () => {
      if (window.outerWidth < 768) {
        setIsSheet(true);
      } else {
        setIsSheet(useSheet || false);
      }
    };
    useEffect(() => {
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [useSheet]);
    useEffect(() => {
      if (!isOpen && onClose) onClose();
    }, [isOpen]);
    if (isSheet)
      return (
        <Sheet
          open={isOpen}
          onOpenChange={(value) =>
            manualClose ? manualClose(() => setIsOpen(false)) : setIsOpen(value)
          }
        >
          {typeof trigger === "function"
            ? trigger(SheetTrigger)
            : trigger &&
              trigger && <SheetTrigger asChild>{trigger}</SheetTrigger>}
          <SheetContent
            className={cn(
              className,
              {
                "w-full": sheetSide == "bottom",
              },
              sheetClassName
            )}
            side={sheetSide}
          >
            {(title || description) && (
              <SheetHeader>
                {title && <SheetTitle>{title}</SheetTitle>}
                <SheetDescription>{description}</SheetDescription>
              </SheetHeader>
            )}
            {children}
            {footer && <SheetFooter className="mt-4">{footer}</SheetFooter>}
          </SheetContent>
        </Sheet>
      );
    return (
      <Dialog
        open={isOpen}
        onOpenChange={(value) =>
          manualClose ? manualClose(() => setIsOpen(false)) : setIsOpen(value)
        }
      >
        {typeof trigger === "function"
          ? trigger(DialogTrigger)
          : trigger &&
            trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
        <DialogContent
          className={cn(
            "overflow-y-scroll max-h-screen",
            className,
            dialogClassName
          )}
        >
          {(title || description) && (
            <DialogHeader>
              {title && <DialogTitle>{title}</DialogTitle>}
              <DialogDescription>{description}</DialogDescription>
            </DialogHeader>
          )}
          {children}
          {footer && <DialogFooter>{footer}</DialogFooter>}
        </DialogContent>
      </Dialog>
    );
  }
);

export default Modal;
