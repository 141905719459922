import Image from "@/components/image";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Link } from "@/i18n/routing";
import { ChevronRightIcon } from "lucide-react";
import { useTranslations } from "next-intl";
import { FC } from "react";

type ProjectProps = {
  name: string;
  title: string;
  href: string;
  types: ["web" | "mobile"];
};

const Project: FC<ProjectProps> = ({ name, title, href, types }) => {
  const t = useTranslations("Home");
  return (
    <div className="flex flex-col bg-gradient-to-tr from-zinc-900 to-zinc-800 border text-sm text-justify border-zinc-700 p-4 rounded-lg gap-1 hover:border-zinc-500 duration-300">
      <div className="flex sm:flex-row flex-col sm:items-end gap-2">
        <div className="my-2 rounded-lg overflow-hidden w-full sm:w-1/3 h-40">
          <Image
            src={`/images/projects/${name}.jpg`}
            alt={name}
            width={480}
            height={320}
            className="h-full w-full object-cover object-top"
          />
        </div>
        <div className="flex flex-col gap-1 flex-1">
          <div className="font-semibold font-mono text-zinc-100">{title}</div>
          <div className="text-zinc-300">
            {t(`sections.projects.apps.${name}.description` as any)}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              {types.includes("web") && (
                <Badge className="bg-zinc-800 border border-zinc-700">
                  Web App
                </Badge>
              )}
              {types.includes("mobile") && (
                <Badge className="bg-zinc-800 border border-zinc-700">
                  Mobile App
                </Badge>
              )}
            </div>
            <Link target="_blank" href={href}>
              <Button
                type="button"
                size="sm"
                className="w-44 group flex items-center border border-zinc-700 mr-0 ml-auto"
              >
                <ChevronRightIcon className="group-hover:ml-2 duration-300 size-4" />
                <span className="flex-1">
                  {t("sections.projects.viewProject")}
                </span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
