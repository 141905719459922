import Image from "@/components/image";
import { Button } from "@/components/ui/button";
import { Link } from "@/i18n/routing";
import { LanguagesIcon } from "lucide-react";
import * as motion from "motion/react-client";
import { useLocale } from "next-intl";

const Header = () => {
  const locale = useLocale();
  return (
    <div className="px-6 py-4 flex items-center justify-between">
      <motion.div
        initial={{
          translateX: -20,
          opacity: 0,
        }}
        animate={{
          translateX: 0,
          opacity: 1,
          transition: { duration: 1 },
        }}
      >
        <Image
          src="/images/logo.jpg"
          alt="Logo"
          width={120}
          height={120}
          className="size-10"
        />
      </motion.div>
      <motion.div
        initial={{
          translateX: 20,
          opacity: 0,
        }}
        animate={{
          translateX: 0,
          opacity: 1,
          transition: { duration: 1 },
        }}
      >
        <Link href="/" locale={locale === "en" ? "it" : "en"}>
          <Button type="button" className="border border-zinc-700">
            <LanguagesIcon className="size-4" />
            {locale === "en" ? "Italiano" : "English"}
          </Button>
        </Link>
      </motion.div>
    </div>
  );
};

export default Header;
